import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Stack = makeShortcode("Stack");
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "application-state"
      }}>{`Application State`}</h1>
      <p>{`As React Application State is a forever evolving topic, there are various methods used across the Rebel front end stack.`}</p>
      <p>{`The earliest React features at Rebel were largely done using `}<a parentName="p" {...{
          "href": "https://redux.js.org/"
        }}>{`Redux`}</a>{` for app state management.`}</p>
      <p>{`As an organization, we've moved away from this package. This was done main due to the inherent complexity and overhead of using Redux. The action/reducer life cycle and general file structure requires a deep understanding and heavy cognitive load for developers.`}</p>
      <Stack mb={3} mdxType="Stack">
        <p>{`Examples of older features still currently using Redux are those found in `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-src/"
          }}>{`rebel-web-src`}</a>{`:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/contact_manager"
            }}>{`Contact Manager`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/dns_manager"
            }}>{`DNS Manager`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/hosting_manager"
            }}>{`Hosting Manager`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/security_manager"
            }}>{`Security Manager`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/speedy-search"
            }}>{`Speedy Search`}</a></li>
          <li parentName="ul">{`Parts of `}<a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/rebel"
            }}>{`Rebel`}</a></li>
        </ul>
      </Stack>
      <p>{`With the advent of React's `}<a parentName="p" {...{
          "href": "https://reactjs.org/docs/context.html"
        }}>{`Context API`}</a>{` and `}<a parentName="p" {...{
          "href": "https://reactjs.org/docs/hooks-intro.html"
        }}>{`Hooks`}</a>{`, many use cases requiring global/app state management can be solved using including funcitonality without a 3rd party package.`}</p>
      <p>{`*`}<strong>{`The latest versions of Redux use the Context API and hooks under the hood anyway.`}</strong></p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "service-based-state-architecture"
      }}>{`Service-based state architecture`}</h2>
      <Stack my={3} mdxType="Stack">
        <p>{`In borrowing concepts from frameworks like Angular, we separate application state by service types.`}</p>
        <p>{`For example, User information is separate from order data, loaders, managers, etc...`}</p>
        <p>{`By separating concerns this way, we can ensure that we keep state changes (and therefore re-renders) as low in the tree as possible. This also allows us to use certain parts of the application state only where they're directly needed. Product Managers are a fantastic example of this.`}</p>
        <p>{`The Basic structure of our Service Providers and the Hooks needed to us them live within the `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-ui"
          }}>{`rebel-web-ui`}</a>{` package's `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-ui/tree/main/src/providers"
          }}>{`providers`}</a>{` folder.`}</p>
        <p>{`There are three main parts to this Provider/Hook pattern:`}</p>
        <ul>
          <li parentName="ul">{`The Context instantiation`}</li>
          <li parentName="ul">{`The Provider definition`}</li>
          <li parentName="ul">{`The Hook funciton`}</li>
        </ul>
      </Stack>
    </Section>
    <Section mdxType="Section">
      <h3 {...{
        "id": "the-context"
      }}>{`The Context`}</h3>
      <p>{`The first thing necessary for this pattern to work is the creation of a React context variable`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`import * as React from 'react';

const MyContext = React.createContext();
`}</code></pre>
    </Section>
    <Section mdxType="Section">
      <Stack mb={3} mdxType="Stack">
        <h3 {...{
          "id": "the-provider"
        }}>{`The Provider`}</h3>
        <p>{`The basic Provider compent is supplied but the newly created Context. At this point, we want to create a new component where we can add the appropriate state and functions necessary.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`import * as React from 'react';

const MyContext = React.createContext();

function MyProvider({ ...props }) {
  const [someState, setSomeState] = React.useState({});

  const businessyLogicFn = (value) => {
    // do some businessy logic things
    setSomeState({
      ...someState,
      value,
    });
  };

  const publicStuff = {
    state: someState,
    businessyLogicFn,
  };

  return <MyContext.Provider value={publicStuff} {...props} />;
}
`}</code></pre>
      </Stack>
      <p>{`At this point, we have a `}<strong><inlineCode parentName="p">{`<MyProvider>`}</inlineCode></strong>{` component which holds `}<inlineCode parentName="p">{`someState`}</inlineCode>{` as well as a function to perform some work.`}</p>
      <p>{`What you'll notice is that we create and assign an object to the Provider's value prop. This becomes the data/functionality that will eventually be accessible to our components via the custom hook we'll be building. We have full control over both the shape and members of this globally available data.`}</p>
      <p>{`For example, maybe we don't want our components to have direct access to the `}<inlineCode parentName="p">{`setSomeState`}</inlineCode>{` function. Maybe we want to rename `}<inlineCode parentName="p">{`someState`}</inlineCode>{` to `}<inlineCode parentName="p">{`state`}</inlineCode>{`. These are all things over which we have control.`}</p>
      <Note mdxType="Note">
  *Note: The MyProvider function returns a React component which can make use of
  other hooks and functionality as any other component would. For example:
  React.useState, React.useEffect, etc....
      </Note>
      <p>{`Logic and use of state within this provider component can be very complex. It's important to note that since this is a component containing children of its own, those children are susceptible to re-renders whenever anything in the Provider changes.`}</p>
    </Section>
    <Section mdxType="Section">
      <h4 {...{
        "id": "usememo"
      }}>{`useMemo`}</h4>
      <p>{`By leveraging the React.useMemo hook, we can use `}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/Memoization"
        }}>{`memoization`}</a>{` to prevent unnecessary re-renders. The idea here is to create a memoized value, where you have control over which parts of your data actually trigger a change to propagate down the tree.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`import * as React from 'react';

const MyContext = React.createContext();

function MyProvider({ ...props }) {
  const [someState, setSomeState] = React.useState({});

  const businessyLogicFn = (value) => {
    // do some businessy logic things
    setSomeState({
      ...someState,
      value,
    });
  };

  const publicStuff = React.useMemo(
    () => ({
      state: someState,
      businessyLogicFn,
    }),
    [
      someState
      businessyLogicFn,
    ]
  )

  return <MyContext.Provider value={publicStuff} {...props} />;
}
`}</code></pre>
      <p>{`Now, we're passing in a memoized value to our context provider.`}</p>
    </Section>
    <Section mdxType="Section">
      <h3 {...{
        "id": "the-hook"
      }}>{`The Hook`}</h3>
      <p>{`Lastly, we need a way to get this data and functionality to the components. For this, we have a few options:`}</p>
      <p>{`The most basic way would be to expert the context variable itself to be imported into a child component of the Provider.`}</p>
      <strong>my-provider.js:</strong>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`export { MyProvider, MyContext };
`}</code></pre>
      <strong>my-child.js</strong>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`import { MyProvider, MyContext } from './my-provider';

/// Inside your components
const { state, businessyLogicFn } = React.useContext(MyContext);
`}</code></pre>
      <p>{`The problem here, is that we have no check to see if the hook is used within the correct provider and exporting the context just feels weird.`}</p>
      <p>{`The answer to this is an appropriately named custom hook in our provider file.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`/// Context ...

/// Provider ...

// Hook
function useMyService() {
  const myCtx = React.useContext(MyContext);
  if (!myCtx) {
    throw new Error(\`useMyService must be used within MyProvider\`);
  }

  return myCtx;
}

export { MyProvider, useMyService };
`}</code></pre>
      <p>{`Now, we can ease our mental model and reference the named hook directly:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`import { useMyService } from './my-provider'

...
const { state, businessyLogicFn } = useMyService()
...
`}</code></pre>
      <p>{`Cool, right?!`}</p>
      <p>{`In the event that we attempt to use the `}<inlineCode parentName="p">{`useMyService()`}</inlineCode>{` call in a component that isn't wrapped in `}<inlineCode parentName="p">{`<MyProvider>`}</inlineCode>{`, we get a friendly console error of "`}<strong>{`useMyService must be used within MyProvider`}</strong>{`" telling you which hook is acting up.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      